import React, { useEffect, useState } from 'react'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import {sendSTKPush } from '../actions/mpesaActions';
import { STK_PUSH_RESET } from '../constants/mpesaConstants';
import { useDispatch, useSelector } from 'react-redux';

function STKForm({STKOpen, setSTKOpen, subscription}) {
  const [phoneNumber, setPhoneNumber] = useState(subscription.phoneNumber)
  const dispatch = useDispatch()
  

  const STKPush = useSelector(state=> state.STKPush)
	const {loading, error, response} = STKPush
    

    

    useEffect(() => {
        setPhoneNumber(subscription.phoneNumber)
        
        
  
        if (response) {
          
          setTimeout(() => {
            dispatch({type:STK_PUSH_RESET})
          }, 5000);
          
        }
        
      }, [dispatch, response, subscription])

  const onSubmitHandler = (e)=>{
		e.preventDefault()
    dispatch(sendSTKPush(phoneNumber, subscription?.subscription_id))
		
	} 
  return (
    <div className={`modal-container ${STKOpen ? 'flex' : 'hidden'}`}>
        {STKOpen && <OutsideAlerter setOpen={setSTKOpen}>
			    <div className="modal-content small">
				<h2 className="modal-header">
					Send Mpesa Prompt
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap py-4' onSubmit={onSubmitHandler}>                                 
                       
                            
                            
                           
            <div className="form-group">
                <label htmlFor="product" className="form-label">Customer</label>
                <input type='text' defaultValue={subscription.customerName} disabled className="form-input" />
            </div>

            <div className="form-group">
                <label htmlFor="product" className="form-label">Package</label>
                <input type='text' defaultValue={subscription.productName} disabled className="form-input" />
            </div>

            <div className="form-group">
                <label htmlFor="product" className="form-label">Contact</label>
                <input type='text' defaultValue={subscription.phoneNumber} disabled className="form-input" />
            </div>

            <div className="w-full flex mb-3 gap-8 px-8">
                <div className="w-1/2 flex flex-col mb-3">
                    <label htmlFor="product" className="form-label">Last Payment</label>
                    <input type='text' defaultValue={subscription.lastOrderDate} disabled className="form-input" />
                </div>
                <div className="w-1/2 flex flex-col mb-3">
                    <label htmlFor="product" className="form-label">Next Payment</label>
                    <input type='text' defaultValue={subscription.nextOrderDate} disabled className="form-input" />
                </div>
                
            </div>
            

            <div className="form-group">
                <label htmlFor="product" className="form-label">Mpesa Number</label>
                
                <PhoneInput
                    onlyCountries={['ke','ug','sn']}
                    country='ke'
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    inputClass='form-input'
                    placeholder={subscription.phoneNumber}
                    copyNumbersOnly={false}
                    countryCodeEditable={false}
                    
                />
            </div>


                                {/* <div className="form-group pl-0">
                                    <label htmlFor="IDNumber" className="form-label">ID Number</label>
                                    <input value={IDNumber}  onChange={(e)=> setIDNumber(e.target.value)} required type="number" placeholder='ID Number' name="IDNumber" id="IDNumber" className="form-input" />
                                </div>
                            


                            </div> */}

                            
                            

                            
                        

                        
                            
                        
                        


						

						
						
						<div className="w-full px-10 hover:scale-101">
							<button type="submit" className='form-submit'>Send</button>
						</div>

                        {error && <Alert variant='error' >{error}</Alert>}

                        {response && <Alert variant='success' >{response?.ResponseDescription}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {loading && <Loader fullscreen />}
	</div>
  )
}

export default STKForm