import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCountyDropdown } from '../actions/dropdownActions'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import {smsCustomer } from '../actions/customerActions';
import { CUSTOMER_SMS_RESET } from '../constants/customerConstants';



function Message({messageOpen, setMessageOpen, subscription}) {
    const dispatch = useDispatch()
    const [text, setText] = useState(`Dear ${subscription.customerName}, \n`)
    const notifyCustomer = useSelector(state=> state.notifyCustomer)
	const {loading, error, response} = notifyCustomer
    

    

    useEffect(() => {
        setText(`Dear ${subscription.customerName}, \n`)
        
        
  
        if (response) {
          setText(`Dear ${subscription.customerName}, \n`)
          setMessageOpen(false)
          dispatch({type: CUSTOMER_SMS_RESET})
        }
        
      }, [dispatch, response, subscription])

      const onSubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(smsCustomer({id:subscription._id, text}))
		
	} 
  return (
    <div className={`modal-container ${messageOpen ? 'flex' : 'hidden'}`}>
            {messageOpen && <OutsideAlerter setOpen={setMessageOpen}>
			    <div className="modal-content small">
				<h2 className="modal-header">
					Send Message
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap py-4' onSubmit={onSubmitHandler}>
                                  
                       
                            
                            
                           
                            <div className="form-group">
                                <label htmlFor="product" className="form-label">Customer</label>
                                <input type='text' defaultValue={subscription.customerName} disabled className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="product" className="form-label">Package</label>
                                <input type='text' defaultValue={subscription.productName} disabled className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="product" className="form-label">Contact</label>
                                <input type='text' defaultValue={subscription.phoneNumber} disabled className="form-input" />
                            </div>

                            <div className="w-full flex mb-3 gap-8 px-8">
                                <div className="w-1/2 flex flex-col mb-3">
                                    <label htmlFor="product" className="form-label">Last Payment</label>
                                    <input type='text' defaultValue={subscription.lastOrderDate} disabled className="form-input" />
                                </div>
                                <div className="w-1/2 flex flex-col mb-3">
                                    <label htmlFor="product" className="form-label">Next Payment</label>
                                    <input type='text' defaultValue={subscription.nextOrderDate} disabled className="form-input" />
                                </div>
                                
                            </div>
                            

                            <div className="form-group">
                                <label htmlFor="product" className="form-label">Message</label>
                                <textarea rows={5} value={text} onChange={(e)=> setText(e.target.value)} required className="form-input textarea" ></textarea>
                            </div>


                                {/* <div className="form-group pl-0">
                                    <label htmlFor="IDNumber" className="form-label">ID Number</label>
                                    <input value={IDNumber}  onChange={(e)=> setIDNumber(e.target.value)} required type="number" placeholder='ID Number' name="IDNumber" id="IDNumber" className="form-input" />
                                </div>
                            


                            </div> */}

                            
                            

                            
                        

                        
                            
                        
                        


						

						
						
						<div className="w-full px-10 hover:scale-101">
							<button type="submit" className='form-submit'>Save</button>
						</div>

                        {error && <Alert variant='error' >{error}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {loading && <Loader fullscreen />}
	</div>
  )
}

export default Message