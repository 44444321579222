import axios from 'axios'
import {
    STK_PUSH_REQUEST,
    STK_PUSH_SUCCESS,
    STK_PUSH_FAIL,

} from '../constants/mpesaConstants'

export const sendSTKPush = (phoneNumber, subscription_id) => async (dispatch, getState) => {

    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        dispatch({ type: STK_PUSH_REQUEST })

        const { data } = await axios.post(`/api/stk/push/`, {phoneNumber, subscription_id}, config)

        dispatch({
            type: STK_PUSH_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: STK_PUSH_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}