import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PaymentForm from '../forms/PaymentForm'
import { deleteSale, listSales, listSubscriptions, updateSale } from '../actions/saleActions';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import idFormatter from '../functions/idFormatter';
import customerFormatter from '../functions/customerFormatter';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { SALE_UPDATE_RESET } from '../constants/saleConstants';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';

function SaleScreen() {
    const [saleOpen, setSaleOpen] = useState(false)
	const [changes, setChanges] = useState([])
    const dispatch = useDispatch()
	const navigate = useNavigate()

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

	const saleList = useSelector(state=> state.saleList)
	const {loading: loadingSales, error: errorSales, sales} = saleList

	const saleCreate = useSelector(state=> state.saleCreate)
	const {success} = saleCreate

	const saleUpdate = useSelector(state => state.saleUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = saleUpdate

	const saleDelete = useSelector(state => state.saleDelete)
    const { error: errorDelete, loading: loadingDelete, success:successDelete} = saleDelete

	



    

	useEffect(() => {
		window.scrollTo(0,0)
		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: SALE_UPDATE_RESET})
				
			}, 2000);
		}
		if(!userInfo){
            navigate('/')
        }else{
	  		dispatch(listSales())
			dispatch(listSubscriptions())
		}
	}, [dispatch, success, successUpdate, userInfo, successDelete])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;

	const handleDelete = (id)=>{
        if (window.confirm(`Permanently Delete this Sale? This action is irreversible`)) {
            dispatch(deleteSale(id))
        }
    }

	
    
	

    const columns = [{
		dataField: '_id',
		text: '#',
		editable: false,
        formatter: idFormatter,
        
	  },{
		dataField:'date',
		text:'Date',
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField: 'customerName',
		text: 'Customer Name',
		sort: true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(sales.map(item => item.customerName))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Customer',
			className:'column-filter'
		})
		
		
		
	  },{
		dataField: 'productName',
		text: 'Package',
		sort: true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(sales.map(item => item.productName))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Package',
			className:'column-filter'
		  })
		
		
	  },{
		dataField:'totalPrice',
		text:'Amount',
		sort:true,
		sortCaret: sortCaret,
        
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button onClick={()=>handleDelete(row._id)} className='delete'> <FaTrash /> </button>
              
            );
          
        }
      }];
  return (
    <div className="container md:px-10">
        <PaymentForm paymentOpen={saleOpen} setPaymentOpen={setSaleOpen} />
        <div className='flex px-0 py-4 flex-row items-center justify-end'>
			
			<button onClick={()=> setSaleOpen(true)}>Add Payment</button>
		</div>

        <div className="dash-card px-0 md:px-4 w-full min-h-[500px] sales">
					
					<div className="table-wrapper">
						
                    {loadingSales || loadingUpdate || loadingDelete?<Loader fullscreen /> :errorSales || errorUpdate? <Alert variant={'error'}>{errorSales || errorUpdate || errorDelete}</Alert>:''}
                    {(!errorSales && !loadingSales) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ sales }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'Producers.csv',
                                exportAll:false
                              } }
							
							  
							  
							 
							
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-fuchsia-500">
										Payments
									</div>
									<div className='flex flex-row space-x-2'>
									<button disabled={changes.length===0} onClick={()=>dispatch(updateSale(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="button csv" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>
								{response && <Alert variant={'success'}>{response}</Alert>}
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
                                 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
					</div>
				</div>
    </div>
  )
}

export default SaleScreen