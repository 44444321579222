import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../components/Alert';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import idFormatter from '../functions/idFormatter';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import SaleForm from '../forms/SaleForm'
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa'
import { listSubscriptions } from '../actions/saleActions';
import { smsCustomer } from '../actions/customerActions';
import MessageForm from '../forms/MessageForm';
import STKForm from '../forms/STKForm';

function SubscriptionScreen() {

    const [subscriptionOpen, setSubscriptionOpen] = useState(false)
	const [subscriber, setSubscriber] = useState({})
	const [messageOpen, setMessageOpen] = useState(false)
	const [STKOpen, setSTKOpen] = useState(false)
	const[changes, setChanges]= useState([])
    const dispatch = useDispatch()

	const navigate = useNavigate()

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
	

	const subscriptionList = useSelector(state=> state.subscriptionList)
	const {loading: loadingSubscriptions, error: errorSubscriptions, subscriptions} = subscriptionList

	const notifyCustomer = useSelector(state=> state.notifyCustomer)
	const {loading: loadingsms, error: errorsms, response} = notifyCustomer

	const saleCreate = useSelector(state=> state.saleCreate)
	const {success} = saleCreate





    

	useEffect(() => {
		window.scrollTo(0,0)
		if(!userInfo){
            navigate('/')
        }else{
			dispatch(listSubscriptions())
		}
		
	}, [dispatch, userInfo, response, success])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;

	const handleSMS = (id)=>{
		dispatch(smsCustomer(id))
	}

	function nextFormatter(cell, row) {
		
		return (
		  <div className={new Date()>new Date(cell)?'bg-rose-400 rounded-lg px-2 py-1 text-black w-fit':'bg-emerald-400 rounded-lg px-2 py-1 text-black w-fit'}>
		   { cell }
		  </div>
		);
	  
	
	  
	}



	
    

    const columns = [{
		dataField:'subscription_id',
		text:'Subscription ID',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
		
        
	  },{
		dataField: 'customerName',
		text: 'Customer',
		sort: true,
		sortCaret: sortCaret,
		editable: false
		
	  },{
		dataField: 'phoneNumber',
		text: 'Contact',
		sort: true,
		sortCaret: sortCaret,
		editable: false
		
	  },{
		dataField:'productName',
		text:'Product',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'lastOrderDate',
		text:'Last Payment',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'nextOrderDate',
		text:'Next Payment',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
		formatter: nextFormatter
        
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Notify',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button className='notify' onClick={()=>{setSubscriber(row); setMessageOpen(true)}}> Send SMS </button>
              
            );
          
        }
      },{
        dataField: 'df2',
        isDummyField: true,
        text: 'Mpesa Prompt',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button className='notify' onClick={()=>{setSubscriber(row); setSTKOpen(true)}}> Prompt </button>
              
            );
          
        }
      }
      ];

	  const expandRow = {
		renderer: row => (
		  <div>
			<p>{ `This Expand row is belong to rowKey ${row._id}` }</p>
			<p>You can render anything here, also you can add additional data on every row object</p>
			<p>expandRow.renderer callback will pass the origin row object to you</p>
		  </div>
		),
		showExpandColumn: true
	  };

	  const sortedSubscription = subscriptions.sort((a, b)=> {return new Date(b.nextOrderDate) - new Date(a.nextOrderDate)})
  return (
    <div className="container px-6">
		<SaleForm saleOpen={subscriptionOpen} setSaleOpen={setSubscriptionOpen} />
		<MessageForm messageOpen={messageOpen} setMessageOpen={setMessageOpen} subscription={subscriber} />
		<STKForm STKOpen={STKOpen} setSTKOpen={setSTKOpen} subscription={subscriber} />


		<div className='flex px-0 py-4 flex-row items-center justify-end'>
			
			<button onClick={()=> setSubscriptionOpen(true)}>Add Subscription</button>
		</div>
        
        
        <div className="dash-card w-full min-h-[500px]">
					
					<div className="table-wrapper">
						{response && <Alert variant={'success'}>{response.status_desc }</Alert>}
                    {loadingSubscriptions || loadingsms ?<Loader fullscreen /> :errorSubscriptions || errorsms? <Alert variant={'error'}>{errorSubscriptions || errorsms}</Alert>:''}
                    {(!errorSubscriptions && !loadingSubscriptions) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ sortedSubscription }
                            columns={ columns }
							expandRow={ expandRow }
							exportCSV={ {
                                fileName: 'Producers.csv',
                                exportAll:false
                              } }
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-fuchsia-500">
										Subscribers
									</div>
									<div className='flex flex-row gap-2 items-center'>
										<div class="rounded-full h-4 w-4 flex items-center justify-center bg-rose-400"></div>
										<span>Overdue</span>
										<div class="rounded-full h-4 w-4 flex items-center justify-center bg-emerald-400"></div>
										<span className='mr-8'>On Schedule</span>
										<ExportCSVButton { ...props.csvProps } className="button csv" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32 form-input' { ...props.searchProps } />
									</div>
									
								</div>

								
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								
                                 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
					</div>
				</div>
    </div>
  )
}

export default SubscriptionScreen