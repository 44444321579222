import React from 'react'
import { NavLink } from 'react-router-dom';
// import { BsMapFill } from 'react-icons/bs';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { GiCampCookingPot, GiReceiveMoney } from 'react-icons/gi';
import { FaDiagnoses, FaCalendar, FaUserFriends, FaShoppingBag } from 'react-icons/fa';
import { FiPackage } from 'react-icons/fi';
import { TfiShoppingCartFull, TfiWorld } from 'react-icons/tfi';
import { MdDashboard } from 'react-icons/md';
import { SlGlobe } from 'react-icons/sl';
import { AiOutlineCloudServer } from 'react-icons/ai';
function SideBar({collapsed, setCollapsed}) {
	
	return (
		<nav className={`sidebar py-2 md:py-0 ${collapsed} `}>
				<div className='my-4 whitespace-nowrap hidden md:flex flex-row items-center justify-center text-slate-700'>
					<AiOutlineCloudServer className='mx-2 h-10 w-10'/>
					<h1 className="title uppercase overflow-hidden">Usoni AI</h1>
				</div>
				<div className=" hidden md:block flex-grow border-t border-fuchsia-300 mt-[-0.325rem] mx-2"></div>
				<ul className="flex md:flex-col mx-2 md:mt-5">
				
					<li>
						<NavLink to={'/home'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<MdDashboard className="nav-link-icon" />
							</div>
								<span className='overflow-hidden'>Dashboard</span>
						</NavLink>
					</li>
					{/* <li>
						<NavLink to={'/clusters'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<BsMapFill className="nav-link-icon" />
							</div>
								<span className='overflow-hidden'>Clusters</span>
						</NavLink>
					</li> */}
					<li>
						<NavLink to={'/packages'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<TfiWorld className="nav-link-icon" />
							</div>
								<span className='overflow-hidden'>Packages</span>
						</NavLink>
					</li>

					<li >
						<NavLink to={'/subscriptions'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaCalendar className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>Subscriptions</span>
						</NavLink>
					</li>

					<li >
						<NavLink to={'/payments'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<GiReceiveMoney className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>Payments</span>
						</NavLink>
					</li>

					<li className='md:mb-20'>
						<NavLink to={'/customers'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaUserFriends className="nav-link-icon" />
							</div>
							<span className={collapsed && 'overflow-hidden'}>Customer Details</span>
						</NavLink>
					</li>
					
					{/* <li>
						<NavLink to={'/subscriptions'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaDiagnoses className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>Subscriptions</span>
						</NavLink>
					</li> */}
					
					

					

					<li className='hidden md:flex justify-center w-full py-[0.675rem] rounded-lg px-4'>
						<div className="group nav-link-icon-wrapper mr-0 cursor-pointer hover:bg-gradient-to-tl hover:from-purple-700 hover:to-fuchsia-500 shadow-lg hover:text-white transition-all duration-300 ease-in-out" onClick={()=>setCollapsed(!collapsed)}>
							<MdOutlineArrowBackIosNew className={`nav-link-icon group-hover:text-white  ${collapsed}`} />
						</div>
							
					</li>

				</ul>

			</nav>
	)
}

export default SideBar