import { 
    STK_PUSH_FAIL,
    STK_PUSH_REQUEST, 
    STK_PUSH_RESET, 
    STK_PUSH_SUCCESS,
 } from '../constants/mpesaConstants';
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
export const STKPushReducer = (state = {}, action) => {
    switch (action.type) {
        case STK_PUSH_REQUEST:
            return { loading: true }

        case STK_PUSH_SUCCESS:
            return { loading: false, success: true, response: action.payload }

        case STK_PUSH_FAIL:
            return { loading: false, error: action.payload }

        case STK_PUSH_RESET:
            return { state: {} }

        default:
            return state
    }
}