import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCountyDropdown } from '../actions/dropdownActions'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import { listSubscriptions, createSale } from '../actions/saleActions';



function PaymentForm({paymentOpen, setPaymentOpen}) {
    const dispatch = useDispatch()
    const [quantity, setQuantity] = useState(1)
    const [unitPrice, setUnitPrice] = useState(0)
    const totalPrice = unitPrice * quantity
    const [subscription, setSubscription] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [location, setLocation] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [product, setProduct] = useState('')
	const [date, setDate] = useState('')
	const [loading, setLoading] = useState(false)
    const [places, setPlaces] = useState([])
    const [placeId, setPlaceId] = useState('')
    const [IDNumber, setIDNumber] = useState('')
    const [households, setHouseholds] = useState('')
    const [gender, setGender] = useState('')
    const [incomeLevel, setIncomeLevel] = useState('')
    const [educationLevel, setEducationLevel] = useState('')
    const [type, setType] = useState('')
    const [renewalValue, setRenewalValue] = useState(1)
    const [renewalUnits, setRenewalUnits] = useState('Months')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [customer, setCustomer] = useState('')
    const [notify, setNotify] = useState(false)

    const subscriptionList = useSelector(state=> state.subscriptionList)
	const {loading: loadingSubscriptions, error: errorSubscriptions, subscriptions} = subscriptionList

    const saleCreate = useSelector(state=> state.saleCreate)
	const {success, loading:loadingCreate, error:errorCreate} = saleCreate

    const handleSubscriptionChange = (newValue, actionMeta) => {
        

        if (newValue){
            setUnitPrice(subscriptions.find(x=>x._id==newValue.value).productPrice)
            setSubscription(newValue.value)
        }else{
            setSubscription('')
        }
        
    };

    useEffect(() => {
	  
        dispatch(listSubscriptions())
        
  
        if (success) {
          setDate('')
          setQuantity(1)
          setFirstName('') 
          setIDNumber('')
          setIncomeLevel('')
          setEducationLevel('')
          setHouseholds('')
          setGender('')
          setType('')
          setPhoneNumber('')
          setLocation('')
          setMaritalStatus('')
          setPaymentOpen(false)
          setProduct('')
          setUnitPrice(0)
          setPlaceId('')
          setNotify(false)
          setRenewalValue(1)
          setRenewalUnits('Months')
          setSubscription('')
        }
        
      }, [dispatch, success, setPaymentOpen])

      const onSubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(createSale({notify, customer, firstName, lastName, email, phoneNumber, IDNumber, location, placeId, gender, households, maritalStatus, educationLevel, date, quantity, product, renewalPeriod: renewalValue, renewalUnits, unitPrice, totalPrice:unitPrice*quantity, subscription}))
		
	} 
  return (
    <div className={`modal-container ${paymentOpen ? 'flex' : 'hidden'}`}>
            {paymentOpen && <OutsideAlerter setOpen={setPaymentOpen}>
			    <div className="modal-content small">
				<h2 className="modal-header">
					Add Payment
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap py-4' onSubmit={onSubmitHandler}>
                                  
                       
                            
                            
                           
                           
                            <div className="form-group">
                                <label htmlFor="date" className="form-label"> Date</label>
                                <input value={date} onChange={(e)=> setDate(e.target.value)} required type="date" name="date" id="date" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="product" className="form-label">Subscription</label>
                                <Select id='product' required isClearable isSearchable options={subscriptions.map((item)=> { return {label:item.customerName + ' ' + item.productName, value:item._id}} )} onChange={handleSubscriptionChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="unitPrice" className="form-label">Amount</label>
                                <input value={unitPrice} onChange={(e)=> setUnitPrice(e.target.value)} required type="number" placeholder='Price' name="unitPrice" id="unitPrice" className="form-input" />
                            </div>

                            <div className="flex gap-2 px-9 -mt-2 mb-3 items-center">
                                
                                <input type="checkbox" className='checkbox' checked={notify} onChange={(e)=>setNotify(e.target.checked)} />
                                <label htmlFor="notify" className="form-label">Notify Customer</label>
                            </div>

                                {/* <div className="form-group pl-0">
                                    <label htmlFor="IDNumber" className="form-label">ID Number</label>
                                    <input value={IDNumber}  onChange={(e)=> setIDNumber(e.target.value)} required type="number" placeholder='ID Number' name="IDNumber" id="IDNumber" className="form-input" />
                                </div>
                            


                            </div> */}

                            
                            

                            
                        

                        
                            
                        
                        


						

						
						
						<div className="w-full px-10 hover:scale-101">
							<button type="submit" className='form-submit'>Save</button>
						</div>

                        {(errorCreate|| errorSubscriptions) && <Alert variant='error' >{errorCreate|| errorSubscriptions}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {(loadingCreate || loadingSubscriptions) && <Loader fullscreen />}
	</div>
  )
}

export default PaymentForm