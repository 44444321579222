import { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import CustomerScreen from "./screens/CustomerScreen";
import DashBoardScreen from "./screens/DashBoardScreen";
import LoginScreen from "./screens/LoginScreen";
import SubscriptionScreen from "./screens/SubscriptionScreen";
import ProductScreen from "./screens/ProductScreen";
import SaleScreen from "./screens/SalesScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ResetScreen from "./screens/ResetScreen";



function App() {
  const [collapsed, setCollapsed] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(window.location.pathname)
  return (
    <Router>
      
      
      
    <div className="d-flex flex-col md:flex-row w-full min-h-screen bg-gray-50">
    {(currentLocation!=='/' && currentLocation!=='/register'&& currentLocation!=='/reset') && <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />}
      <main className={`ml-0 md:ml-60 transition-all duration-700 ease-in-out ${collapsed}`}>
        <Header currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} />
        <Routes>
          <Route path="/"  element={<LoginScreen setCollapsed={setCollapsed} />} />
          <Route path="/home" element={<DashBoardScreen />} />
          <Route path="/packages" element={<ProductScreen />} />
          <Route path="/subscriptions" element={<SubscriptionScreen />} />
          <Route path="/customers" element={<CustomerScreen />} />
          <Route path="/payments" element={<SaleScreen />} />
          <Route path="/register" element={<RegisterScreen setCollapsed={setCollapsed}/>} />
          <Route path="/reset"  element={<ResetScreen setCollapsed={setCollapsed} />} />
        </Routes>
      </main>
      
    </div>
    </Router>
  );
}

export default App;
