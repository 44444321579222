import React, { useEffect } from 'react'
// import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { GiCampCookingPot} from 'react-icons/gi';
import { TfiShoppingCartFull, TfiWorld } from 'react-icons/tfi';
import { AiOutlineShop } from 'react-icons/ai'
import { FaCoins, FaCalendar, FaUserFriends } from 'react-icons/fa';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts'
import DashChart from '../components/DashChart';
import { useNavigate } from 'react-router-dom';
import { getProductSalesTrend, getSalesPerCustomer, getSalesPerProduct, getSalesTrend, listSales, getSalesPerCounty, getSubscriptionsPerProduct, getSubscriptionsTrend, getProductSubscriptionsTrend, listSubscriptions } from '../actions/saleActions';
import { listProducts } from '../actions/productActions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import Alert from '../components/Alert';

function DashBoardScreen() {

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const dispatch = useDispatch()
	const navigate = useNavigate()



	useEffect(() => {
        window.scrollTo(0,0)
        if(!userInfo){
            navigate('/')
        }else{
            dispatch(listProducts())
            dispatch(listSales())
			dispatch(listSubscriptions())
            dispatch(getSalesTrend())
            dispatch(getSalesPerProduct())
            dispatch(getProductSalesTrend())
			dispatch(getSalesPerCustomer())
			dispatch(getSubscriptionsPerProduct())
			dispatch(getSubscriptionsTrend())
			dispatch(getProductSubscriptionsTrend())
			// dispatch(getSalesPerCluster())
			// dispatch(getSalesPerCounty())
        }
        
    }, [userInfo, dispatch])

	const productList = useSelector(state => state.productList)
    const{loading:loadingProducts, error: errorProducts, products} = productList

	const saleList = useSelector(state=> state.saleList)
	const {loading: loadingSales, error: errorSales, sales} = saleList

	const salesPerProduct = useSelector(state => state.salesPerProduct)
	const {loading:loadingPerProduct, error:errorPerProduct, perProducts} = salesPerProduct

	const salesPerCustomer = useSelector(state => state.salesPerCustomer)
	const {loading:loadingPerCustomer, error:errorPerCustomer, perCustomers} = salesPerCustomer

	const salesPerCounty = useSelector(state => state.salesPerCounty)
	const {loading:loadingPerCounty, error:errorPerCounty, perCountys} = salesPerCounty

	const subscriptionList = useSelector(state=> state.subscriptionList)
	const {loading: loadingSubscriptions, error: errorSubscriptions, subscriptions} = subscriptionList


	const productSalesTrend = useSelector(state => state.productSalesTrend)
    const {loading, error, productTrends} = productSalesTrend

	const salesTrend = useSelector(state => state.salesTrend)
    const {loading:loadingTrend, error:errorTrend, trends} = salesTrend

	const subscriptionsPerProduct = useSelector(state => state.subscriptionsPerProduct)
	const {loading:loadingSubcribersPerProduct, error:errorSubcribersPerProduct, subcribersPerProduct} = subscriptionsPerProduct

	const productSubscriptionsTrend = useSelector(state => state.productSubscriptionsTrend)
    const {loading:loadingSubscribersProductTrends, error:errorSubscribersProductTrends, subscribersProductTrends} = productSubscriptionsTrend

	const subscriptionsTrend = useSelector(state => state.subscriptionsTrend)
    const {loading:loadingSubscriberTrends, error:errorSubscriberTrends, subscriberTrends} = subscriptionsTrend
	
	
	
	const trendSeries = products.map((p)=>{
		var filteredArray = subscribersProductTrends.filter(item => item.product===p.name+' '+p.size)
		
          trends.forEach(element => {
            if (!(filteredArray.find(item => item.month===element.month))){
              filteredArray.push({
                  month:element.month,
                  totalCash:0,
                  totalQuantity:0,
                  product: p.name+''+p.size,
              })
            }
          });
		  const productArray = filteredArray.sort((a,b) => new Date(a.month).getTime() - new Date(b.month).getTime())
		  return {name: p.name +' '+ p.size, data: productArray.map((item)=> { return {x:new Date(item.month).toLocaleString('default', { month: 'long' })+' '+new Date(item.month).getFullYear(), y:parseInt(item.totalCash)}} )}
	})

	const salesTrendSeries = products.map((p)=>{
		var filteredArray = productTrends.filter(item => item.product===p.name+' '+p.size)
		
          trends.forEach(element => {
            if (!(filteredArray.find(item => item.month===element.month))){
              filteredArray.push({
                  month:element.month,
                  totalCash:0,
                  totalQuantity:0,
                  product: p.name+''+p.size,
              })
            }
          });
		  const productArray = filteredArray.sort((a,b) => new Date(a.month).getTime() - new Date(b.month).getTime())
		  return {name: p.name +' '+ p.size, data: productArray.map((item)=> { return {x:new Date(item.month).toLocaleString('default', { month: 'long' })+' '+new Date(item.month).getFullYear(), y:parseInt(item.totalCash)}} )}
	})

	

	  const options = {
		
		chart: {
		  height: 500,
		  type: 'area',
		  
		  zoom: {
			enabled: false
		  }
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth'
		},
		
		tooltip: {
			x: {
			  format: 'dd/MM/yyyy'
			},
		  },
		}

		const pieSeries =  subcribersPerProduct.map(item=>item.totalCash)
		
		const pieOptions = {
			chart: {
			
			type: 'pie',
			},
			
			labels: subcribersPerProduct.map(item=>item.product),
			responsive: [{
				breakpoint: 480,
					options: {
						chart: {
						
						},
						legend: {
						position: 'bottom'
						}
					}
				}],
			fill: {
				type: 'gradient',
			},
			dataLabels: {
				enabled: false
			}

		}

		// const donutSeries =  perSaletypes.map(item=>item.totalQuantity)

		// const donutOptions = {
		// 	chart: {
			
		// 	type: 'pie',
		// 	},
			
		// 	labels: perSaletypes.map(item=>item.type),
		// 	responsive: [{
		// 	breakpoint: 480,
		// 		options: {
		// 			chart: {
					
		// 			},
		// 			legend: {
		// 			position: 'bottom'
		// 			}
		// 		}
		// 	}],
		// 	fill: {
		// 		type: 'gradient',
		// 	},
		// 	dataLabels: {
		// 		enabled: false
		// 	}

		// }

		const barSeries = [{
			name: "Sales",
			data: perCustomers.map((item)=>{return {x:item.Customer, y:item.totalQuantity}})
		}]

		
		
		const barOptions = {
			fill:{
				opacity: 0.4,
				
			},

			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'round',
				
				width: 2,
				dashArray: 0,      
			},
			
			chart: {
			  type: 'bar',
			  redrawOnParentResize: true
			  
			},
			plotOptions: {
			  bar: {
				borderRadius: 4,
				horizontal: false,
				distributed: true
			  }
			},
			dataLabels: {
			  enabled: false
			},
			
			legend:{
				show: false
			}
			
			

		  }
		  useEffect(() => {
			window.scrollTo(0,0)
		  }, [])
		
		  const topSales = sales.sort((a, b)=> {return b.sales - a.sales})
		  const topProducts = perProducts.sort((a, b)=> {return b.totalCash - a.totalCash})
		  const topCustomers = perCustomers.sort((a, b)=> {return b.totalCash - a.totalCash})
		  const overDueSubs = subscriptions.filter((s)=>new Date()>new Date(s.nextOrderDate))
	return (
		<div className="container">
			
			
			{(loadingPerCustomer || loadingPerProduct || loading || loadingTrend || loadingProducts || loadingSales || loadingSales) ? <Loader fullscreen />: 
			(errorPerCustomer || errorPerProduct || error || errorTrend || errorProducts || errorSales || errorSales) ? <Alert variant={'error'}>{errorPerCustomer || errorPerProduct || error || errorTrend || errorProducts || errorSales || errorSales}</Alert>:
			<div className="row">
				
				<div className="col-4">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Total Packages</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={products.length}
										duration={1}
										separator=","
										
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<TfiWorld className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-4">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Total Subscriptions</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={subscriptions.length}
										duration={1}
										separator=","
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<FaUserFriends className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-4">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Overdue Payments</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={overDueSubs.length}
										duration={1}
										separator=","
										
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<FaCalendar className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-4">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Total Payments Received</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={sales.reduce((acc, item) => acc + Number(item.totalPrice), 0)}
										duration={1}
										separator=","
										prefix='Ksh. '
										
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<FaCoins className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>

				
				
			</div>}
			<div className="row md:mt-6">
					<div className='col-2'>
					<div className="dash-card w-full md:min-h-[500px]">
						<div className="card-title px-4 font-bold text-fuchsia-700">
							Payments Trend
						</div>
						<div className="w-auto overflow-hidden">
							{(!loadingTrend && !loading && !loadingProducts) && <ReactApexChart options={options} series={salesTrendSeries} type="area" height={400} />}
						</div>
						
					</div>

					</div>

					<div className='col-2'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-fuchsia-700">
								OverDue Payments
							</div>
							<div className="table-wrapper">
								<table className='items-center w-full mb-0 align-top border-gray-200 text-slate-500'>
									<thead>
										<tr>
											<th>Name</th>
											<th >Package</th>
											<th >Due Date</th>
											<th className='text-right'>Amount</th>
										</tr>
									</thead>
									<tbody>
										{loadingSubscriptions? <tr></tr> : errorSubscriptions? <tr></tr>:overDueSubs.map(l=>
											<tr key={l._id}>
												<td>{l.customerName}</td>
												<td>{l.productName}</td>
												<td>{l.nextOrderDate}</td>
												<td className='font-bold text-right'>{l.productPrice}</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							
						</div>

						
					</div>
			</div>

			<div className="row md:mt-6 ">
				<div className='col-2'>
					<div className="dash-card w-full md:min-h-[500px]">
						<div className="card-title px-4 font-bold text-fuchsia-700">
							Subscriptions Trend
						</div>
						<div className="w-auto overflow-hidden">
							{(!loadingSubscribersProductTrends && !errorSubscribersProductTrends && !loadingProducts) && <ReactApexChart options={options} series={trendSeries} type="area" height={400} />}
						</div>
						
					</div>
				</div>

				<div className='col-2'>
					<div className="dash-card w-full md:min-h-[500px]">
						<div className="card-title px-4 font-bold text-fuchsia-700">
							Subscriptions Per Product
						</div>
						<div className="w-full overflow-hidden">
							{(!errorPerProduct && !loadingPerProduct) &&<DashChart options={pieOptions} data={pieSeries} type={"pie"} height={400}/>}
						</div>
						
					</div>
				</div>

				

				



			</div>

			{/* <div className="row md:mt-6">
					<div className='col-2'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-fuchsia-700">
								Sales Per Cluster
							</div>
							<div className="w-full overflow-hidden">
								{ (!loadingPerCluster && !errorPerCluster)&& <DashChart options={barOptions} data={barSeries} type={"bar"} height={400} width={'100%'}/>}
							</div>
							
						</div>

						
					</div>

					<div className='col-2'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-fuchsia-700">
								Sales Per Sale Type
							</div>
							{ (!loadingSales && !errorSales) && <DashChart options={pieOptions} data={pieSeries} type={"donut"} height={400} />}
							
						</div>

						
					</div>
			</div> */}

			
		</div>
		
	)
}

export default DashBoardScreen