import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { deleteProduct, listProducts, updateProduct } from '../actions/productActions'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import idFormatter from '../functions/idFormatter';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FaTrash } from 'react-icons/fa';
import { SiMicrosoftexcel } from 'react-icons/si'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'
import ProductForm from '../forms/ProductForm'

function ProductScreen() {
	const [productOpen, setProductOpen] = useState(false)
	const[changes, setChanges]= useState([])
	const navigate = useNavigate()
  	const dispatch = useDispatch()
	const productList = useSelector(state=> state.productList)
	const {error:errorProducts, loading:loadingProducts, products} = productList

	const userLogin = useSelector(state=> state.userLogin)
	const {userInfo} = userLogin

	const productCreate = useSelector(state=> state.productCreate)
	const {success} = productCreate

	const productUpdate = useSelector(state => state.productUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = productUpdate

	const productDelete = useSelector(state => state.productDelete)
    const { error: errorDelete, loading: loadingDelete, success:successDelete} = productDelete

    

	useEffect(() => {
		window.scrollTo(0,0)
		if(!userInfo){
            navigate('/')
        }else{
			dispatch(listProducts())
		}
		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: PRODUCT_UPDATE_RESET})
				
			}, 2000);
		}
	}, [dispatch, success, successUpdate, userInfo, successDelete])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;

	const handleDelete = (id)=>{
        if (window.confirm(`Permanently Delete this Package? This action is irreversible`)) {
            dispatch(deleteProduct(id))
        }
    }

    

    const columns = [{
		dataField: '_id',
		text: '#',
		editable: false,
        formatter: idFormatter,
        
	  }, {
		dataField: 'name',
		text: 'Package Name',
		sort: true,
		sortCaret: sortCaret,
		
	  },{
		dataField:'price',
		text:'Price',
		editable: true,
		sort:true,
		sortCaret: sortCaret
	  },{
		dataField:'size',
		text:'Speed',
		editable: true,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button onClick={()=>handleDelete(row._id)} className='delete'> <FaTrash /> </button>
              
            );
          
        }
      }];

	  
  return (
	<div className="container md:px-10">
        <ProductForm productOpen={productOpen} setProductOpen={setProductOpen} />
        <div className='flex px-0 py-4 flex-row items-center justify-end'>
			
			<button onClick={()=> setProductOpen(true)}>Add Package</button>
		</div>

        <div className="dash-card px-0 md:px-4 w-full min-h-[500px] products">
					
					<div className="table-wrapper">
                    {loadingProducts || loadingUpdate || loadingDelete?<Loader fullscreen /> :errorProducts || errorUpdate? <Alert variant={'error'}>{errorProducts || errorUpdate || errorDelete}</Alert>:''}
                    {(!errorProducts && !loadingProducts) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ products }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'Producers.csv',
                                exportAll:false
                              } }
							  
							 
							
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-fuchsia-500">
										Packages
									</div>
									<div className='flex flex-row space-x-2'>
									<button disabled={changes.length===0} onClick={()=>dispatch(updateProduct(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="button csv" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>
								{response && <Alert variant={'success'}>{response}</Alert>}
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
                                 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
					</div>
				</div>
    </div>
  )
}

export default ProductScreen